var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.registerShow)?_c('el-card',{staticClass:"register animated fadeIn"},[_c('el-steps',{staticClass:"title",attrs:{"space":110,"active":this.$route.query.userId ? 2 : _vm.active,"finish-status":"success","align-center":true}},[_c('el-step',{attrs:{"title":"用戶信息"}}),_c('el-step',{attrs:{"title":"开票信息"}}),_c('el-step',{attrs:{"title":"收货信息"}})],1),_c('i',{staticClass:"el-icon-close clbtn",on:{"click":_vm.goback}}),_c('el-form',{ref:"form0",attrs:{"model":_vm.info,"label-width":"100px"}},[_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 0),expression:"active === 0"}],ref:"userName",attrs:{"prop":"userName","label":"用户名","rules":[
          {message: '请输入用户名', trigger: 'blur' } ]}},[_c('el-input',{attrs:{"disabled":!_vm.vsb},model:{value:(_vm.info.userName),callback:function ($$v) {_vm.$set(_vm.info, "userName", $$v)},expression:"info.userName"}})],1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 0),expression:"active === 0"}],ref:"email",attrs:{"prop":"email","label":"邮箱","rules":[
          {message: '请输入邮箱', trigger: 'blur' },
          { pattern: /^[a-zA-Z0-9_-\W]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: '邮箱输入有误' }
        ]}},[_c('el-input',{attrs:{"disabled":!_vm.vsb},model:{value:(_vm.info.email),callback:function ($$v) {_vm.$set(_vm.info, "email", $$v)},expression:"info.email"}})],1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 0),expression:"active === 0"}],ref:"loginphone",attrs:{"prop":"loginPhone","label":"手机号","rules":[
          { required: true, message: '请输入手机号', trigger: 'change' },
          { pattern: /^1[34578]\d{9}$/, message: '电话号输入有误' }
        ]}},[_c('el-input',{attrs:{"oninput":"if(value.length>11)value=value.slice(0,11)","disabled":!_vm.vsb},on:{"blur":_vm.ifNumber},model:{value:(_vm.info.loginPhone),callback:function ($$v) {_vm.$set(_vm.info, "loginPhone", $$v)},expression:"info.loginPhone"}})],1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 0),expression:"active === 0"}],attrs:{"label":"验证码","rules":[
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]}},[_c('el-input',{staticClass:"vcode",attrs:{"disabled":!_vm.vsb},model:{value:(_vm.vcode),callback:function ($$v) {_vm.vcode=$$v},expression:"vcode"}}),_c('el-button',{attrs:{"disabled":_vm.isDisabled},on:{"click":_vm.sendCode}},[_vm._v(_vm._s(_vm.buttonName))])],1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 0),expression:"active === 0"}],attrs:{"prop":"password","label":"密码","rules":[{ required: true, message: '请输入密码', trigger: 'blur' }]}},[_c('el-input',{attrs:{"disabled":!_vm.vsb},model:{value:(_vm.info.password),callback:function ($$v) {_vm.$set(_vm.info, "password", $$v)},expression:"info.password"}})],1)],1),_c('el-form',{ref:"form1",attrs:{"model":_vm.info.enterpriseInfos[0],"label-width":"100px"}},[_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 1),expression:"active === 1"}],attrs:{"prop":"name","label":"企业名称","rules":[
          { message: '请输入企业名称', trigger: 'blur' }
        ]}},[_c('el-input',{attrs:{"disabled":!_vm.vsb},model:{value:(_vm.info.enterpriseInfos[0].name),callback:function ($$v) {_vm.$set(_vm.info.enterpriseInfos[0], "name", $$v)},expression:"info.enterpriseInfos[0].name"}})],1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 1),expression:"active === 1"}],attrs:{"prop":"creditCode","label":"信用代码","rules":[
          { message: '请输入信用代码', trigger: 'blur' }
        ]}},[_c('el-input',{ref:_vm.refList.cardInput,attrs:{"disabled":!_vm.vsb},on:{"input":function($event){return _vm.inputBlur(_vm.info.enterpriseInfos[0].creditCode)}},model:{value:(_vm.info.enterpriseInfos[0].creditCode),callback:function ($$v) {_vm.$set(_vm.info.enterpriseInfos[0], "creditCode", $$v)},expression:"info.enterpriseInfos[0].creditCode"}})],1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 1),expression:"active === 1"}],attrs:{"prop":"registerAddress","label":"注册地址","rules":[
          { message: '请输入注册地址', trigger: 'blur' }
        ]}},[_c('el-input',{attrs:{"disabled":!_vm.vsb},model:{value:(_vm.info.enterpriseInfos[0].registerAddress),callback:function ($$v) {_vm.$set(_vm.info.enterpriseInfos[0], "registerAddress", $$v)},expression:"info.enterpriseInfos[0].registerAddress"}})],1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 1),expression:"active === 1"}],attrs:{"prop":"phone","label":"注册电话","rules":[
          {  message: '请输入注册电话', trigger: 'blur' },
          { pattern: /\d/, message: '电话号输入有误' }
        ]}},[_c('el-input',{attrs:{"disabled":!_vm.vsb},model:{value:(_vm.info.enterpriseInfos[0].phone),callback:function ($$v) {_vm.$set(_vm.info.enterpriseInfos[0], "phone", $$v)},expression:"info.enterpriseInfos[0].phone"}})],1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 1),expression:"active === 1"}],attrs:{"prop":"accountOpening","label":"开户行","rules":[
          { message: '请输入开户行', trigger: 'blur' }
        ]}},[_c('el-input',{model:{value:(_vm.info.enterpriseInfos[0].accountOpening),callback:function ($$v) {_vm.$set(_vm.info.enterpriseInfos[0], "accountOpening", $$v)},expression:"info.enterpriseInfos[0].accountOpening"}})],1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 1),expression:"active === 1"}],attrs:{"prop":"bankAccount","label":"银行账号","rules":[
          {  message: '请输入银行账号', trigger: 'blur' }
        ]}},[_c('el-input',{ref:_vm.refList.cardInputs,on:{"input":function($event){return _vm.formatCardNumber(_vm.refList.cardInputs,_vm.info.enterpriseInfos[0].bankAccount, _vm.info)}},model:{value:(_vm.info.enterpriseInfos[0].bankAccount),callback:function ($$v) {_vm.$set(_vm.info.enterpriseInfos[0], "bankAccount", $$v)},expression:"info.enterpriseInfos[0].bankAccount"}})],1)],1),_c('el-form',{ref:"form3",attrs:{"model":_vm.info.enterpriseAddresses[0],"label-width":"100px"}},[_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 2),expression:"active === 2"}],attrs:{"prop":"name","label":"收货人","rules":[
          { required: true, message: '请输入收货人名称', trigger: 'blur' }
        ]}},[_c('el-input',{model:{value:(_vm.info.enterpriseAddresses[0].name),callback:function ($$v) {_vm.$set(_vm.info.enterpriseAddresses[0], "name", $$v)},expression:"info.enterpriseAddresses[0].name"}})],1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 2),expression:"active === 2"}],attrs:{"prop":"name","label":"店名","rules":[
          {  message: '店名', trigger: 'blur' }
        ]}},[_c('el-input',{model:{value:(_vm.info.enterpriseAddresses[0].companyName),callback:function ($$v) {_vm.$set(_vm.info.enterpriseAddresses[0], "companyName", $$v)},expression:"info.enterpriseAddresses[0].companyName"}})],1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 2),expression:"active === 2"}],attrs:{"prop":"phone","label":"收货电话","rules":[
          { required: true, message: '请输入注册电话', trigger: 'blur' },
          { pattern: /^1[34578]\d{9}$/, message: '电话号输入有误' }
        ]}},[_c('el-input',{model:{value:(_vm.info.enterpriseAddresses[0].phone),callback:function ($$v) {_vm.$set(_vm.info.enterpriseAddresses[0], "phone", $$v)},expression:"info.enterpriseAddresses[0].phone"}})],1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 2),expression:"active === 2"}],attrs:{"prop":"address","label":"收货地址","rules":[
          { required: true, message: '请输入收货地址', trigger: 'blur' }
        ]}},[_c('el-input',{model:{value:(_vm.info.enterpriseAddresses[0].address),callback:function ($$v) {_vm.$set(_vm.info.enterpriseAddresses[0], "address", $$v)},expression:"info.enterpriseAddresses[0].address"}})],1),_c('el-form-item',[_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.active !== 2),expression:"active !== 2"}],on:{"click":_vm.next}},[_vm._v("下一步")])],1),(_vm.vsb)?_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 2),expression:"active === 2"}]},[_c('el-button',{staticClass:"resbutton",attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("立即注册")])],1):_vm._e(),(!_vm.vsb)?_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 2),expression:"active === 2"}]},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("立即修改")]),_c('el-button',{on:{"click":_vm.onCancel}},[_vm._v("取消修改")])],1):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }